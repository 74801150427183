import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

export interface SearchItemProps {
    packId: string,
    thumbnail: string,
    packName: string,
    creatorName?: string,
    onClickPack: () => void,
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minWidth: '360px',
    },
    packThumbnail: {
        width: '68px',
        height: '68px',
        backgroundSize: 'cover',
        margin: 'auto',
    },
    packInfoBox: {
        margin: '16px 16px 16px 16px',
    },
    imageBox: {
        width: '80px',
        height: '80px',
        border: '0.5px solid',
        display: 'flex',
        borderColor: theme.greyscaleColorTheme.lightGrey3.main,
    },
    packName: {
        color: theme.greyscaleColorTheme.darkGrey.main,
        width: '232px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    creatorName: {
        color: theme.greyscaleColorTheme.grey2.main,
        marginTop: '3px',
        width: '232px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}))

function SearchItem({ packId, thumbnail, packName, creatorName, onClickPack }: SearchItemProps) {

    const classes = useStyles()

    return (
        <div className={classes.root} onClick={onClickPack}>
            <div className={classes.imageBox}> <img className={classes.packThumbnail} src={thumbnail} alt={packName}></img></div>
            <div className={classes.packInfoBox}>
                <Typography className={classes.packName} variant="subtitle1">{packName}</Typography>
                <Typography className={classes.creatorName} variant="caption">{creatorName}</Typography>
            </div>
        </div>
    )
}
export default SearchItem