import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RedirectPage } from "../types/RedirectPage";
import { getAuthData } from "../utils";

function StoreInstantAuth() {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const page = params.get("page");
  const id = params.get("id");
  const paymentType = params.get("payment");
  const language = params.get("lang");

  getAuthData(token ?? "", "WEB", paymentType ?? "", language ?? undefined);

  switch (page) {
    case RedirectPage.PACK:
      history.replace(`/pack?packId=${id}`);
      break;
    case RedirectPage.MYPACKS:
      history.replace(`/mypacks`);
      break;
    default:
      history.replace({
        pathname: "/",
      });
      break;
  }

  return <></>;
}

export default StoreInstantAuth;
