import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import WeeklyHotPack, {
  WeeklyHotPackProps,
} from "../components/home/WeeklyHotPack";
import curationServices from "../services/CurationService";
import { StickerPack } from "../types/ResponseSchema";
import mvcBanner from "../img/mvc_banner.png";
import { osName } from "react-device-detect";
import { useTranslation } from "react-i18next";
import FreePack, { FreePackProps } from "../components/home/FreePack";
import FreePackService from "../services/FreePackService";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "960px",
    margin: "auto",
  },
  weeklyHotBox: {
    marginTop: "24px",
  },
  weeklyHotTitle: {
    color: theme.greyscaleColorTheme.darkGrey.main,
    ...theme.typography.h4,
    marginBottom: "16px",
    marginLeft: "16px",
  },
  weeklyHotPack: {
    display: "inline-block",
    marginRight: "8px",
  },

  trending: {
    marginTop: "24px",
  },
  trendingTitle: {
    ...theme.typography.h4,
    marginLeft: "16px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  trendingDesc: {
    ...theme.customTypography.body3,
    marginBottom: "16px",
    marginLeft: "16px",
    color: theme.greyscaleColorTheme.grey2.main,
  },
  freeTitle: {
    ...theme.typography.h4,
    marginLeft: "16px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  freeDesc: {
    ...theme.customTypography.body3,
    marginBottom: "16px",
    marginLeft: "16px",
    color: theme.greyscaleColorTheme.grey2.main,
  },

  themes: {
    marginTop: "24px",
    marginBottom: "8px",
  },
  themeTitle: {
    display: "flex",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  themeTitleShap: {
    color: theme.brandColorTheme.mojitokBlue.main,
    marginRight: "4px",
  },
  themeBox: {
    marginTop: "24px",
  },
  themeConcept: {
    color: theme.greyscaleColorTheme.darkGrey.main,
  },

  weeklyHotPacks: {
    display: "flex",
    overflow: "auto",
    padding: "16px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  trendingPacks: {
    display: "flex",
    overflow: "auto",
    padding: "16px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  freePacks: {
    display: "flex",
    overflow: "auto",
    padding: "16px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

function Home() {
  let history = useHistory();
  const classes = useStyles(true);

  const [weeklyHotPacks, setWeeklyHotPacks] = useState<Array<StickerPack>>([]);
  const [recommendedPacks, setRecommendedPacks] = useState<Array<StickerPack>>(
    []
  );
  const [themePacks, setThemePacks] = useState<
    Array<{ theme: string; stickerPacks: Array<StickerPack> }>
  >([]);
  const [freePacks, setFreePacks] = useState<Array<StickerPack>>([]);
  const { t, i18n } = useTranslation();

  async function getSessionStorageAsync() {
    try {
      const data = (await curationServices.getCurations()).data;
      data.forEach((curation) => {
        const stickerPacks =
          curation.data.length > 0 ? curation.data[0].stickerPacks : [];
        if (curation.type === "WEEKLY_HOT") {
          setWeeklyHotPacks(stickerPacks);
        } else if (curation.type === "RECOMMENDED") {
          setRecommendedPacks(stickerPacks.slice(0, 6));
        } else if (curation.type === "THEME") {
          const themes = curation.data.map((theme) => {
            return {
              theme: theme.theme!,
              stickerPacks: theme.stickerPacks,
            };
          });
          setThemePacks(themes);
        } else {
          throw new Error(
            `Failed to recognize which curation type of api result is ${curation.type}`
          );
        }
      });

      const freePack = (await FreePackService.getFreePack()).data;
      if (freePack) {
        setFreePacks(freePack);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem("settingDone") !== null) {
      getSessionStorageAsync();
    } else {
      getSessionStorageAsync();
    }
    window.addEventListener("settingDone", getSessionStorageAsync, false);
    return () => {
      window.removeEventListener("settingDone", getSessionStorageAsync);
    };
  }, []);

  const onClickPack = (packId: string) => {
    history.push(`/pack?packId=${packId}`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.weeklyHotBox}>
        <Typography className={classes.weeklyHotTitle} variant="h6">
          {t("home_weekly_hot")}
        </Typography>
        <div className={classes.weeklyHotPacks}>
          {weeklyHotPacks.map((pack, index) => {
            const { id, name, thumbnails, creator } = pack;

            let thumbnail: string;
            let foundName = "";
            try {
              thumbnail = thumbnails!!.find((image) => image.spec === "w250")!!
                .url;
              foundName = name.find(
                (lang: { content: string; language: string }) =>
                  lang.language === "en"
              )!!.content;
            } catch (err) {
              console.log(`Failed to get thumbnail from weeklyHotPacks`);
              thumbnail = "";
            }

            const props: WeeklyHotPackProps = {
              packId: id,
              packName: foundName,
              thumbnail: thumbnail,
              creatorName: creator!!.nickname.find(
                (name) => name.language === "en"
              )!!.content,
              markNumber: index + 1,
              onClickPack: onClickPack,
            };

            return (
              <div
                className={classes.weeklyHotPack}
                key={id}
                style={
                  index === weeklyHotPacks.length - 1
                    ? { paddingRight: "16px" }
                    : {}
                }
              >
                <WeeklyHotPack {...props} />
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.trending}>
        <Typography className={classes.trendingTitle} variant="h6">
          {t("home_recommended")}
        </Typography>
        <Typography className={classes.trendingDesc} variant="body1">
          {t("home_recommended_dsc")}
        </Typography>
        <div className={classes.trendingPacks}>
          {recommendedPacks.map((pack, index) => {
            const { id, name, thumbnails, creator } = pack;

            let thumbnail: string;
            let foundName = "";
            try {
              thumbnail = thumbnails.find((image) => image.spec === "w250")!!
                .url;
              foundName = name.find(
                (lang: { content: string; language: string }) =>
                  lang.language === "en"
              )!!.content;
            } catch (err) {
              console.log(`Failed to get thumbnail from recommendedPacks`);
              thumbnail = "";
            }

            const props: WeeklyHotPackProps = {
              packId: id,
              packName: foundName,
              thumbnail: thumbnail,
              creatorName: creator!!.nickname.find(
                (name) => name.language === "en"
              )!!.content,
              onClickPack: onClickPack,
            };

            return (
              <div
                className={classes.weeklyHotPack}
                key={id}
                style={
                  index === recommendedPacks.length - 1
                    ? { paddingRight: "16px" }
                    : {}
                }
              >
                <WeeklyHotPack {...props} />
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.weeklyHotBox}>
        <Typography className={classes.freeTitle} variant="h6">
          {t("home_free_pack")}
        </Typography>
        <Typography className={classes.freeDesc} variant="body1">
          {t("home_free_pack_dsc")}
        </Typography>
        <div className={classes.freePacks}>
          {freePacks.map((pack, index) => {
            const { id, name, thumbnails, creator } = pack;

            let thumbnail: string;
            let foundName = "";
            try {
              thumbnail = thumbnails!!.find((image) => image.spec === "w250")!!
                .url;
              foundName = name.find(
                (lang: { content: string; language: string }) =>
                  lang.language === "en"
              )!!.content;
            } catch (err) {
              console.log(`Failed to get thumbnail from freePack`);
              thumbnail = "";
            }

            const props: FreePackProps = {
              packId: id,
              packName: foundName,
              thumbnail: thumbnail,
              creatorName: creator!!.nickname.find(
                (name) => name.language === "en"
              )!!.content,
              onClickPack: onClickPack,
            };

            return (
              <div
                className={classes.weeklyHotPack}
                key={id}
                style={
                  index === freePacks.length - 1 ? { paddingRight: "16px" } : {}
                }
              >
                <FreePack {...props} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Home;
