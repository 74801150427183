import { AxiosResponse } from "axios";
import http from "../http-product";
import { CatalogueCategory, StickerPack } from "../types/ResponseSchema";

export interface CatalogueCategoryResBody {
  data: CatalogueCategory[];
}
export interface CatalogueCategorySearchResBody {
  data: StickerPack[];
}

const getCatalogueCategory = async () => {
  const token = sessionStorage.getItem("token");
  if (token === null) {
    throw new Error(
      `Failed to get token from device, ${JSON.stringify(sessionStorage)}`
    );
  }
  let lang = sessionStorage.getItem("language");
  if (lang === null) {
    lang = "en";
  }

  let result: AxiosResponse<CatalogueCategoryResBody>;
  try {
    result = await http.get(`/tags/catalogue-category`, {
      headers: { "Mojitok-User-Access-Token": token },
      params: {
        lang: lang,
      },
    });
  } catch (err) {
    throw new Error(`Failed to request catalogue category, err: ${err})`);
  }

  if (result.status !== 200) {
    throw new Error(
      `Failed to request catalogue category, invalid status(${result.status})`
    );
  }

  return result.data;
};

const searchCatalogueCategory = async (name: string) => {
  const token = sessionStorage.getItem("token");
  if (token === null) {
    throw new Error(
      `Failed to get token from device, ${JSON.stringify(sessionStorage)}`
    );
  }
  let lang = sessionStorage.getItem("language");
  if (lang === null) {
    lang = "en";
  }
  let result: AxiosResponse<CatalogueCategorySearchResBody>;
  try {
    result = await http.get(`/packs`, {
      headers: { "Mojitok-User-Access-Token": token },
      params: {
        catalogue_category: name,
        lang: lang,
        include: "CREATOR",
      },
    });
  } catch (err) {
    throw new Error(
      `Failed to request catalogue category search, err: ${err})`
    );
  }

  if (result.status !== 200) {
    throw new Error(
      `Failed to request catalogue category search, invalid status(${result.status})`
    );
  }

  return result.data;
};

export default {
  getCatalogueCategory,
  searchCatalogueCategory,
};
