import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  GridList,
  GridListTile,
  makeStyles,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import SearchService from "../services/SearchService";
import { StickerPack } from "../types/ResponseSchema";
import SearchItem from "../components/search/SearchItem";
import NoSearchResult from "../components/search/NoSearchResult";
import { Status } from "../types/status";

const useStyles = makeStyles((theme) => ({
  root: (isPC: boolean) =>
    isPC
      ? {
          height: "inherit",
          margin: "0px 8px 8px 8px",
        }
      : {
          height: "calc(100% - 56px)",
          margin: "0px 8px 8px 8px",
        },
  progressBox: {
    display: "flex",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255,0.5)",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 99998,
  },
  circularProgress: {
    color: theme.brandColorTheme.mojitokBlue.main,
    position: "fixed",
    zIndex: 99999,
  },
  gridList: {
    display: "inline",
    margin: "8px 16px 8px 16px",
    transform: "translateZ(0)",
  },
  searchItem: {
    margin: "8px 16px 8px 16px",
  },
}));

export interface SearchResultProps {
  isPC: boolean;
}
interface SearchLocationState {
  query: string;
  language: string;
}

function SearchResult({ isPC }: SearchResultProps) {
  const [searchResultData, setSearchResultData] = useState<Array<StickerPack>>(
    []
  );
  const classes = useStyles(isPC === true);
  const [status, setStatus] = useState(Status.READY);
  const [errorCode, setErrorCode] = useState(0);
  const location = useLocation<SearchLocationState>();
  const history = useHistory();

  const onClickPack = (packId: string) => {
    return function () {
      history.push(`/pack?packId=${packId}`);
    };
  };

  useEffect(() => {
    async function searchPack(query: string, language: string) {
      setStatus(Status.READY);
      try {
        const searchResult = (await SearchService.searchPacks(query, language))
          .data;
        setSearchResultData(searchResult);
        setStatus(Status.SUCCESS);
      } catch (err) {
        if (err.code) {
          setErrorCode(err.code);
        } else {
          setErrorCode(0);
        }
        setStatus(Status.ERROR);
      }
    }
    if (!location.state) {
      setSearchResultData([]);
      setStatus(Status.SUCCESS);
    } else {
      if (location.state.query.length !== 0) {
        searchPack(location.state.query, location.state.language);
      }
    }
  }, [location]);

  return (
    <>
      {status === Status.READY ? (
        <div className={classes.progressBox}>
          <CircularProgress className={classes.circularProgress} size={24} />
        </div>
      ) : status === Status.SUCCESS ? (
        <div className={classes.root}>
          {searchResultData.length > 0 ? (
            <GridList
              className={classes.gridList}
              spacing={16}
              cellHeight={82}
              cols={1}
            >
              {searchResultData.map((item) => (
                <GridListTile
                  key={
                    item.thumbnails.find((image) => image.spec === "w250")!!.url
                  }
                >
                  <SearchItem
                    packId={item.id}
                    thumbnail={
                      item.thumbnails.find((image) => image.spec === "w250")!!
                        .url
                    }
                    packName={
                      item.name.find(
                        (lang: { content: string; language: string }) =>
                          lang.language === "en"
                      )!!.content
                    }
                    creatorName={
                      item.creator?.nickname.find(
                        (lang: { content: string; language: string }) =>
                          lang.language === "en"
                      )!!.content
                    }
                    onClickPack={onClickPack(item.id)}
                  />
                </GridListTile>
              ))}
            </GridList>
          ) : (
            <NoSearchResult />
          )}
        </div>
      ) : (
        <div></div>
        //TODO : Error Code 에 따른 View처리
      )}
    </>
  );
}

export default SearchResult;
