import React from "react";
import { makeStyles, Typography, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface ProductPriceProps {
  priceText: string;
}

const useStyles = makeStyles((theme) => ({
  productPriceBox: {
    marginLeft: "16px",
    marginRight: "16px",
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: "0.5px",
    marginBottom: "20px",
  },
  priceTitle: {
    marginTop: "56px",
    marginBottom: "16px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  line: {
    display: "flex",
    marginBottom: "16px",
    justifyContent: "space-between",
  },
  lastLine: {
    display: "flex",
    justifyContent: "space-between",
  },
  productPrice: {
    color: theme.greyscaleColorTheme.grey3.main,
  },
  totalPrice: {
    color: theme.brandColorTheme.mojitokBlue.main,
  },
}));

function ProductPrice(props: ProductPriceProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.productPriceBox}>
      <Typography className={classes.priceTitle} variant="h6" noWrap>
        {t("payment_amount")}
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.line}>
        <Typography className={classes.productPrice} variant="body1">
          {t("payment_subtotal")}
        </Typography>
        <Typography className={classes.productPrice} variant="body1">
          {props.priceText}
        </Typography>
      </div>
      <div className={classes.lastLine}>
        <Typography className={classes.totalPrice} variant="body2">
          {t("payment_total")}
        </Typography>
        <Typography className={classes.totalPrice} variant="h2">
          {props.priceText}
        </Typography>
      </div>
    </div>
  );
}

export default ProductPrice;
