import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SearchService from "../services/SearchService";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  searchBar: {
    display: "flex",
    height: "56px",
  },
  searchBarBackButton: {
    margin: "20px",
  },
  searchTitle: {
    margin: "24px 16px",
  },
  searchTrendingTag: {
    margin: "0px 16px",
  },
  searchTrendingTagGrid: {
    display: "flex",
    whiteSpace: "nowrap",
    flexWrap: "wrap",
  },
  searchTrendingTagItem: {
    ...theme.customTypography.body3,
    color: theme.greyscaleColorTheme.darkGrey.main,
    padding: "5px 12px",
    margin: "0px 0px 8px 8px",
    border: "0.5px",
    borderStyle: "solid",
    borderColor: theme.greyscaleColorTheme.lightGrey3.main,
  },
  searchNewTag: {
    marginLeft: "8px",
  },
}));

export interface SearchViewProps {
  isPC: boolean;
}

const fakeKeyword = [
  "Happy",
  "Cute",
  "Lovely",
  "Funny",
  "Friends",
  "Dog",
  "Cat",
  "Rabbit",
  "Bear",
  "Duck",
];

function SearchView({ isPC }: SearchViewProps) {
  let history = useHistory();
  const classes = useStyles();
  const [trendingTag, setTrendingTag] = useState<Array<string>>([]);
  const [newPacks, setNewPacks] = useState<Array<string>>([]);
  const { t } = useTranslation();

  useEffect(() => {
    async function getTrendingTag() {
      try {
        //const trendingTag = (await SearchService.getTrendingTag()).data;
        const trendingTag = fakeKeyword;

        setTrendingTag(trendingTag);
      } catch (e) {
        //treding tag를 불러오지 못한 경우
        console.log(e);
        history.push("/");
      }
    }

    getTrendingTag();
  }, []);

  const onTagClick = (query: string) => {
    history.push({
      pathname: "/packs/search",
      state: { query: query, language: "en" },
    });
  };
  return (
    <div className={classes.root}>
      <Typography className={classes.searchTitle} variant="h4" noWrap>
        {t("searching_title")}
      </Typography>
      <div className={classes.searchTrendingTag}>
        <Grid item className={classes.searchTrendingTagGrid}>
          {trendingTag.map((tag) => (
            <Typography
              className={classes.searchTrendingTagItem}
              onClick={() => {
                onTagClick(tag);
              }}
            >
              {tag}
            </Typography>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default SearchView;
