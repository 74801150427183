import React, { useEffect, useState } from "react";
import { Button, Divider, makeStyles, Typography } from "@material-ui/core";
import { isAndroid, isIOS } from "react-device-detect";
import { useHistory } from "react-router-dom";

import "../../theme";
import useWindowSize from "../../utils/useWindowSize";
import { purchaseFinish, purchaseStart } from "../../utils/eventDispatcher";
import { useTranslation } from "react-i18next";
import postOwnerShipService from "../../services/OwnershipService";
import studioIcon from "../../img/studio_icon.svg";
import { updateOwnership } from "../../utils/ownershipInSessionStorage";

export interface PackViewInfoProps {
  packId: string;
  thumbnail: string;
  packName: string;
  creatorName: string;
  priceType: string;
  productType: string;
  price: number;
  priceText: string | undefined;
  promotion: string;
  isPurchased: boolean;
  isWaitingPurchase: boolean;
}

const useStyles = makeStyles((theme) => ({
  packViewInfo: {
    maxWidth: "360px",
    margin: "0 0 0 24px",
    display: "flex",
  },
  packViewThumbnail: {
    width: "104px",
    height: "104px",
  },
  packThumbnailBox: {
    position: "relative",
    display: "flex",
    backgroundColor: theme.greyscaleColorTheme.white.main,
    margin: "24px 24px 32px 0px",
  },

  packViewMetaBox: {
    marginTop: "24px",
  },
  packName: {
    width: "192px",
    color: theme.greyscaleColorTheme.darkGrey.main,
    fontFamily: "Noto Sans KR Bold",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "-0.25px",
    textTransform: "none",

    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
  },
  creatorInfoBox: {
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
  },
  creatorButton: {
    maxWidth: "575px",
    padding: "3px 8px 5px 8px",
    borderRadius: 0,
    boxSizing: "border-box",
    border: `0.5px solid ${theme.greyscaleColorTheme.lightGrey3.main}`,
  },
  creatorName: {
    color: theme.brandColorTheme.mojitokBlue.main,
    ...theme.customTypography.body4,
  },
  studioIcon: {
    marginRight: "8.58px",
    width: "14px",
    height: "14px",
  },
  freeMark: {
    width: "30px",
    height: "30px",
    position: "absolute",
    right: "0px",
  },
  findMe: {
    ...theme.typography.button,
    color: theme.brandColorTheme.mojitokBlue.main,
    textDecoration: "underline",
    minWidth: "45px",
    height: "18px",
    marginLeft: "8px",
    padding: "0px 0px 0px 0px",
    fontSize: "12px",
  },
  horizontalDivider: {
    marginTop: "24px",
    marginBottom: "16px",
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: "0.5px",
    display: "none",
  },
  verticalDivider: {
    display: "null",
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    width: "1px",
    height: "8px",
    marginLeft: "6px",
    marginRight: "8px",
  },
  packPrice: {
    color: theme.greyscaleColorTheme.darkGrey.main,
    ...theme.customTypography.body4,
  },
  packPromotion: {
    color: theme.greyscaleColorTheme.darkGrey.main,
    marginTop: "0px",
    ...theme.customTypography.body4,
  },
  purchaseButton: {
    position: "fixed",
    width: "100%",
    height: "48px",
    marginTop: "0px",
    bottom: "0px",
    left: "0px",
    backgroundColor: theme.brandColorTheme.mojitokBlue.main,
    color: theme.greyscaleColorTheme.white.main,
    ...theme.typography.subtitle1,
    borderRadius: 0,
    zIndex: 99999,
  },
  priceInfoBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "17px",
    marginBottom: "21px",
  },
  purchasedButton: (isPurchased: boolean) =>
    isPurchased
      ? {
          backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
          color: theme.greyscaleColorTheme.grey2.main,
        }
      : {
          backgroundColor: theme.brandColorTheme.mojitokBlue.main,
          color: theme.greyscaleColorTheme.white.main,
        },
}));

function PackViewInfo(props: PackViewInfoProps) {
  const history = useHistory();
  const windowSize = useWindowSize();
  const {
    packId,
    thumbnail,
    packName,
    creatorName,
    price,
    priceText,
    promotion,
    priceType,
    productType,
    isPurchased,
    isWaitingPurchase,
  } = props;
  const classes = useStyles(isPurchased);

  const [buttonLock, setButtonLock] = useState(true);
  const [purchaseState, setPurchaseState] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isWaitingPurchase) {
      setButtonLock(true);
    } else {
      setButtonLock(false);
    }
    if (isWaitingPurchase) setPurchaseState(false);
  }, [isWaitingPurchase]);

  useEffect(() => {
    if (purchaseState) {
      purchaseLaunch();
    }
  }, [purchaseState]);

  async function freePackRequest(packId: string) {
    try {
      const result = await postOwnerShipService.postOwnerships(packId);

      if (result.data.success === true) {
        updateOwnership({ include: "STICKER_PACK" })
          .then(() => {
            console.log("update ownership in PackView");
            purchaseFinish();
          })
          .catch((err) => {
            setButtonLock(false);
            console.error(err);
            alert(err);
          });
      } else {
        console.log("result data false");
        setButtonLock(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const purchasePack = () => {
    if (isPurchased || !priceType) return;
    setButtonLock(true);
    if (priceType === "FREE") {
      freePackRequest(packId);
    } else {
      purchaseStart();
      setPurchaseState(true);
    }
  };

  const purchaseLaunch = () => {
    const paymentType = sessionStorage.getItem("paymentType");
    if (paymentType === "GOOGLE_PLAY_STORE" || isAndroid) {
      (window as any).android
        ? (window as any).android.onPurchase(packId)
        : console.log(`paymentType, ${paymentType} may not set properly`);
    } else if (paymentType === "APPLE_APP_STORE" || isIOS) {
      (window as any).webkit
        ? (window as any).webkit.messageHandlers.onPurchase.postMessage(packId)
        : console.log("paymentType, IOS may not set properly.");
    } else {
      history.push({
        pathname: "/payment",
        state: {
          packId,
          thumbnail,
          packName,
          creatorName,
          price,
          promotion,
          priceText,
        },
      });
    }
  };

  return (
    <div className={classes.packViewInfo}>
      <div className={classes.packThumbnailBox}>
        <img
          className={classes.packViewThumbnail}
          src={thumbnail}
          alt={packName}
        />
        {priceType === "FREE" ? (
          <img
            className={classes.freeMark}
            src={require(`../../img/Mark_free.svg`)}
            alt={`Mark free`}
          />
        ) : null}
      </div>

      <div className={classes.packViewMetaBox}>
        <Typography
          className={classes.packName}
          style={{ width: `${(windowSize ? windowSize.width : 360) - 168}px` }}
          variant="h6"
        >
          {packName}
        </Typography>
        <div
          className={classes.creatorInfoBox}
          style={{ width: `${(windowSize ? windowSize.width : 360) - 176}px` }}
        >
          <Button className={classes.creatorButton}>
            <img
              className={classes.studioIcon}
              src={studioIcon}
              alt="studio_icon"
            ></img>
            <Typography className={classes.creatorName} noWrap>
              {creatorName}
            </Typography>
          </Button>
        </div>
        <Divider className={classes.horizontalDivider} />
        <div>
          <div className={classes.priceInfoBox}>
            <Typography className={classes.packPrice} variant="h6">
              {priceText}
            </Typography>
            <Divider
              className={classes.verticalDivider}
              orientation="vertical"
            />
            <Typography className={classes.packPromotion} variant="body1">
              {promotion}
            </Typography>
          </div>
        </div>
        <Button
          id={"purchase_button"}
          className={`${classes.purchaseButton} ${classes.purchasedButton}`}
          onClick={purchasePack}
          style={
            isPurchased
              ? { background: "#E5E5E5", color: "#979797" }
              : { background: "#3278FF", color: "#FFFFFF" }
          }
          disabled={isPurchased || buttonLock}
        >
          {priceType === "FREE" && !isPurchased
            ? t("product_info_free_download")
            : isPurchased
            ? t("product_info_downloaded")
            : t("product_info_purchase")}
        </Button>
      </div>
    </div>
  );
}

export default PackViewInfo;
