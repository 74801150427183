import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import PaymentMethod from "./PaymentMethod";
import BillingDetail from "./BillingDetail";
import ProductPrice from "./ProductPrice";
import DanalPaymentService from "../../services/DanalPaymentService";
import EximbayPaymentService from "../../services/EximbayPaymentService";
import BootpayService from "../../services/BootpayService";
import { PaymentMethodType } from "../../types";
import { useTranslation } from "react-i18next";

export interface PaymentFormProps {
  packId: string;
  priceText: string;
  price: number;
}

const useStyles = makeStyles((theme) => ({
  billingButton: {
    width: "100%",
    height: "56px",
    marginTop: "auto",
    backgroundColor: theme.brandColorTheme.mojitokBlue.main,
    color: theme.greyscaleColorTheme.white.main,
    ...theme.typography.body2,
    borderRadius: 0,
  },
  eximbayInfoBox: {
    marginRight: "16px",
    marginLeft: "16px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  eximbayInfo: {
    ...theme.customTypography.body4,
    color: theme.greyscaleColorTheme.grey2.main,
    textDecoration: "none",
  },
  dialogRoot: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  dialogTitle: {
    marginTop: "8px",
    marginBottom: "16px",
    ...theme.typography.body2,
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  dialogContext: {
    marginTop: "8px",
    ...theme.customTypography.body3,
    color: theme.greyscaleColorTheme.grey2.main,
  },
  dialogOkBtn: {
    marginTop: "8px",
    padding: "0px",
    width: "100%",
    height: "48px",
    borderRadius: "0px",
    backgroundColor: theme.brandColorTheme.mojitokBlue.main,
    color: theme.greyscaleColorTheme.white.main,
    ...theme.typography.subtitle1,
  },
}));

function PaymentForm(props: PaymentFormProps) {
  const classes = useStyles();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>(
    PaymentMethodType.CARD
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const paymentType = "EXIMBAY";
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onPaymentMethodChange = (changedPaymentMethod: PaymentMethodType) => {
    setPaymentMethod(changedPaymentMethod);
  };

  const onBillingDetailChange = (changedName: string, changedEmail: string) => {
    setName(changedName);
    setEmail(changedEmail);
  };

  const onBillingButtonClick = async () => {
    if (!name || !email) {
      handleClickOpen();
      return;
    }
    try {
      //TODO: 이후 결제방식이 늘어날 경우 이곳에 추가
      await EximbayPaymentService.openPaymentWindow({
        price: props.price,
        packId: props.packId,
        currency: "USD",
        name,
        email,
        lang: "EN",
        productType: "STICKER_PACK",
      });
    } catch (err) {
      alert(`결제 서버 접속에 실패했습니다.`);
    }
  };

  return (
    <>
      <BillingDetail onChange={onBillingDetailChange} />
      <ProductPrice priceText={props.priceText} />
      {paymentType === "EXIMBAY" ? (
        <>
          <div className={classes.eximbayInfoBox}>
            <Typography className={classes.eximbayInfo}>
              {t("payment_eximbay_title_01")}
              {
                <a
                  className={classes.eximbayInfo}
                  href="https://www.eximbay.com"
                  target="_blank"
                  style={{ color: "#3278FF" }}
                >
                  {" "}
                  www.eximbay.com
                </a>
              }
            </Typography>
          </div>
          <div
            className={classes.eximbayInfoBox}
            style={{ marginBottom: "56px" }}
          >
            <Typography className={classes.eximbayInfo}>
              {t("payment_eximbay_title_02")}
              {
                <a
                  className={classes.eximbayInfo}
                  href="https://EXIMBAY.COM"
                  target="_blank"
                  style={{ color: "#3278FF" }}
                >
                  {" "}
                  EXIMBAY.COM
                </a>
              }
            </Typography>
          </div>
        </>
      ) : null}
      <Button className={classes.billingButton} onClick={onBillingButtonClick}>
        {t("payment_title")}
      </Button>
      <Dialog
        className={classes.dialogRoot}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent>
          <Typography className={classes.dialogTitle} variant="body2">
            {t("error_empty_order_info")}
          </Typography>
          {name === "" ? (
            <Typography className={classes.dialogContext}>
              {t("error_required_name")}
            </Typography>
          ) : null}
          {email === "" ? (
            <Typography className={classes.dialogContext}>
              {t("error_required_email")}
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions style={{ padding: "0px", marginTop: "24px" }}>
          <Button
            className={classes.dialogOkBtn}
            autoFocus
            onClick={handleClose}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PaymentForm;
