import React from 'react'

export const newLineToDiv = (text: string) => {
    return text.split('\n').map(text => (<div>{text}</div>))
}

export const parseJwtPayload = (token: string) => {
    try{
        var tokenData = token.split('.')[1]
        var base64Decode = atob(tokenData.replace(/_/g, '/').replace(/-/g, '+'))
        const payload = JSON.parse(base64Decode);
        return payload
    }
    catch{
        throw new Error(`Failed to parse payload data`)
    }
};