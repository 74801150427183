import { AxiosResponse } from 'axios';
import http from '../http-product';
import { Curation } from '../types/ResponseSchema';
export interface CurationsResBody {
  data: Curation[]
}

const getCurations = async () => {
  
  let isToken = false;
  const token = sessionStorage.getItem('token')
  if (token === null) {
    // throw new Error(`Failed to get token from device, ${JSON.stringify(sessionStorage)}`)
    isToken = false;
  }else{
    isToken = true;
  }

  let result: AxiosResponse<CurationsResBody>
  try {
    if(isToken){
      result = await http.get('/curations',
      {
        headers: { 'Mojitok-User-Access-Token': token },
      }
    )
    }else{
      result = await http.get('/curations')
    }
    
  } catch (err) {
    throw new Error(`Failed to request curations api, err: ${err})`)
  }

  if (result.status !== 200) {
    throw new Error(`Failed to request curations api, invalid status(${result.status})`)
  }

  return result.data;
}

export default {
  getCurations,
}