import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, makeStyles, Typography } from "@material-ui/core";
import OrderInfo from "../components/payment/OrderInfo";
import PaymentForm from "../components/payment/PaymentForm";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paymentTitle: {
    marginTop: "48px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
}));

export interface PaymentProps {
  isPC: boolean;
}

interface PaymentStatus {
  packId: string;
  thumbnail: string;
  packName: string;
  creatorName: string;
  price: number;
  priceText: string;
  promotion: string;
}

function Payment({ isPC }: PaymentProps) {
  const location = useLocation<PaymentStatus>();
  const {
    packId,
    thumbnail,
    packName,
    creatorName,
    price,
    priceText,
    promotion,
  } = location.state;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <OrderInfo
        thumbnail={thumbnail}
        packName={packName}
        creatorName={creatorName}
        price={priceText}
        promotion={promotion}
      />
      <PaymentForm packId={packId} price={price} priceText={priceText} />
    </div>
  );
}

export default Payment;
