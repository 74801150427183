import http from "../http-ownership";
import { Ownership, StickerPack } from "../types/ResponseSchema";

import { AxiosResponse } from "axios";

export interface FreePackResBody {
  data: StickerPack[];
}

const getFreePack = async () => {
  const token = sessionStorage.getItem("token");
  if (token === null) {
    throw new Error(
      `Failed to get token from device, ${JSON.stringify(sessionStorage)}`
    );
  }

  let result: AxiosResponse<FreePackResBody>;
  const includeStringArray: string[] = ["CREATOR", "STICKER"];
  try {
    result = await http.get("/packs/free", {
      headers: { "Mojitok-User-Access-Token": token },
      params: {
        include: includeStringArray,
      },
    });
  } catch (err) {
    throw new Error(`Failed to request free pack api, err: ${err}`);
  }
  if (result.status !== 200) {
    throw new Error(
      `Failed to request free pack api, invalid status(${result.status})`
    );
  }

  return result.data;
};

export default {
  getFreePack,
};
