import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import "../../theme";

export interface FreePackProps {
  packId: string;
  thumbnail: string;
  packName: string;
  creatorName: string;
  onClickPack: Function;
}

const useStyles = makeStyles((theme) => ({
  freePack: {
    width: "128px",
    maxHeight: "200px",
  },
  packThumbnailBox: {
    width: "128px",
    height: "128px",
    position: "relative",
    display: "flex",
    backgroundColor: theme.greyscaleColorTheme.white.main,
    boxShadow: "inset 0px 0px 1px rgba(0, 0, 0, 0.35)",
  },
  packThumbnail: {
    width: "112px",
    height: "112px",
    margin: "auto",
  },
  packMetaBox: {
    width: "128px",
    marginTop: "8px",
  },
  packName: {
    color: theme.greyscaleColorTheme.darkGrey.main,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
  },
  packCreator: {
    ...theme.customTypography.body4,
    color: theme.greyscaleColorTheme.grey2.main,
    marginTop: "2px",
  },
  freeMark: {
    width: "30px",
    height: "30px",
    position: "absolute",
    right: "0px",
  },
  spotlightMark: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
}));

function FreePack({
  packId,
  thumbnail,
  packName,
  creatorName,
  onClickPack,
}: FreePackProps) {
  const classes = useStyles();

  return (
    <div
      className={classes.freePack}
      id={packName}
      onClick={() => onClickPack(packId)}
    >
      <div className={classes.packThumbnailBox}>
        <img className={classes.packThumbnail} src={thumbnail} alt={packName} />
        <img
          className={classes.freeMark}
          src={require(`../../img/Mark_free.svg`)}
          alt={`mark_free}`}
        />
      </div>
      <div className={classes.packMetaBox}>
        <Typography className={classes.packName} variant="subtitle1">
          {packName}
        </Typography>
        <Typography className={classes.packCreator} noWrap>
          {creatorName}
        </Typography>
      </div>
    </div>
  );
}

export default FreePack;
