import React from "react";
import { Divider, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface OrderInfoProps {
  thumbnail: string;
  packName: string;
  creatorName: string;
  price: string;
  promotion: string;
}

const useStyles = makeStyles((theme) => ({
  orderInfoBox: {
    marginRight: "16px",
    marginLeft: "16px",
  },
  orderInfoTitle: {
    marginTop: "24px",
    marginBottom: "16px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: "0.5px",
  },
  orderPackInfo: {
    display: "flex",
    justifyContent: "space-between",
  },
  orderInfoThumbnail: {
    width: "104px",
    height: "104px",
    marginBottom: "16px",
    marginRight: "16px",
  },
  orderInfoPackDescription: {
    display: "flex",
    flexDirection: "column",
  },
  packName: {
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  creatorName: {
    ...theme.customTypography.body3,
    marginTop: "4px",
    color: theme.greyscaleColorTheme.grey2.main,
  },
  price: {
    marginTop: "16px",
    color: theme.brandColorTheme.mojitokBlue.main,
  },
  orderPackInfoFirstPiece: {
    marginTop: "16px",
    display: "flex",
  },
  orderPackInfoSecondPiece: {
    display: "flex",
  },
  promotion: {
    marginLeft: "16px",
    marginTop: "16px",
    color: theme.greyscaleColorTheme.grey2.main,
  },
  priceAndPromotionBox: {
    display: "flex",
  },
}));

function OrderInfo(props: OrderInfoProps) {
  const classes = useStyles();
  const { thumbnail, packName, creatorName, price, promotion } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.orderInfoBox}>
      <Typography className={classes.orderInfoTitle} variant="h6" noWrap>
        {t("payment_order_info")}
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.orderPackInfo}>
        <div className={classes.orderPackInfoFirstPiece}>
          <img
            className={classes.orderInfoThumbnail}
            src={thumbnail}
            alt={packName}
          />
          <div className={classes.orderInfoPackDescription}>
            <Typography className={classes.packName} variant="body2" noWrap>
              {packName}
            </Typography>
            <Typography className={classes.creatorName} noWrap>
              {creatorName}
            </Typography>
            <div className={classes.priceAndPromotionBox}>
              <Typography className={classes.price} variant="body2" noWrap>
                {price}
              </Typography>
              <Typography className={classes.promotion} variant="body1" noWrap>
                {promotion}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
    </div>
  );
}

export default OrderInfo;
