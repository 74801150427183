import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Image } from "../../types/ResponseSchema";

export interface CategoryItemProps {
  name: string;
  bgColor: string;
  image: Image[];
  onClickCategory: Function;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  categoryName: {
    position: "absolute",
    marginTop: "8px",
    marginLeft: "8px",
  },
  categoryImage: {
    position: "absolute",
    width: "90px",
    height: "90px",
    bottom: 0,
    right: 0,
    marginRight: "5px",
  },
}));

function CategoryItem({
  name,
  bgColor,
  image,
  onClickCategory,
}: CategoryItemProps) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: bgColor }}
      onClick={() => onClickCategory(name)}
    >
      <Typography className={classes.categoryName} variant="subtitle2">
        {name}
      </Typography>
      <img
        className={classes.categoryImage}
        src={image!!.find((image) => image.spec === "w300")!!.url}
        alt="categoryImageThumbnail"
      ></img>
    </div>
  );
}
export default CategoryItem;
