import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import tranEn from "./Files/en.json";
import tranKo from "./Files/ko.json";
import tranId from "./Files/id.json";
import tranVi from "./Files/vi.json";

export const languages = ["en", "ko", "in", "id", "vi"] as const;

const resources = {
  en: { translation: tranEn },
  ko: { translation: tranKo },
  in: { translation: tranId },
  id: { translation: tranId },
  vi: { translation: tranVi },
};

const userLanguage = window.navigator.language; //IE >= 11 , Chrome O , FireFox O

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
