import http from "../http-ownership";
import { Ownership } from "../types/ResponseSchema";

import { AxiosResponse } from "axios";

export interface OwnershipResBody {
  data: Ownership[];
}

interface FreeOwnerShipResBody {
  data: {
    success: boolean;
  };
}
const getOwnerships = async (params?: { [key: string]: string }) => {
  const token = sessionStorage.getItem("token");
  if (token === null) {
    throw new Error(
      `Failed to get token from device, ${JSON.stringify(sessionStorage)}`
    );
  }

  let result: AxiosResponse<OwnershipResBody>;
  try {
    result = await http.get("/me/ownership", {
      headers: { "Mojitok-User-Access-Token": token },
      params: params,
    });
  } catch (err) {
    throw new Error(`Failed to request ownership api, err: ${err}`);
  }

  if (result.status !== 200) {
    throw new Error(
      `Failed to request ownership api, invalid status(${result.status})`
    );
  }

  return result.data;
};

const postOwnerships = async (packId: string) => {
  const token = sessionStorage.getItem("token");
  if (token === null) {
    throw new Error(
      `Failed to get token from device, ${JSON.stringify(sessionStorage)}`
    );
  }

  let result: AxiosResponse<FreeOwnerShipResBody>;
  try {
    result = await http.post(
      "/me/ownership",
      {
        productId: packId,
        productType: "STICKER_PACK",
      },
      { headers: { "Mojitok-User-Access-Token": token } }
    );
  } catch (err) {
    throw new Error(`Failed to request free ownership api, err: ${err}`);
  }

  if (result.status !== 200) {
    throw new Error(
      `Failed to request free ownership api, invalid status(${result.status})`
    );
  }

  return result.data;
};

export default {
  getOwnerships,
  postOwnerships,
};
