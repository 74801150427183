import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  Divider,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface BillingDetailProps {
  onChange: (name: string, email: string) => void;
}

const useStyles = makeStyles((theme) => ({
  billingDetailBox: {
    marginRight: "16px",
    marginLeft: "16px",
  },
  billingDetail: {
    marginTop: "56px",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  billingDetailTitle: {
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  billingRequired: {
    display: "flex",
  },
  billingRequiredStar: {
    color: theme.brandColorTheme.orange.main,
    ...theme.customTypography.body4,
  },
  billingRequiredText: {
    color: theme.greyscaleColorTheme.grey2.main,
    ...theme.customTypography.body4,
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: "0.5px",
    marginBottom: "24px",
  },
  line: {
    marginBottom: "16px",
  },
  lastLine: {},
  inputTitlePart: {
    display: "flex",
    marginBottom: "8px",
  },
  inputTitle: {
    color: theme.greyscaleColorTheme.grey3.main,
  },
  inputRequired: {
    color: theme.brandColorTheme.orange.main,
  },
  textField: {
    width: "100%",
  },
  textInput: {
    padding: "9px 12px",
    borderRadius: 0,
    ...theme.customTypography.body3,
  },
}));

const StoreTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#101010",
      },
    },
  },
})(TextField);

function BillingDetail(props: BillingDetailProps) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedName = event.target.value;
    setName(changedName);
    props.onChange(changedName, email);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedEmail = event.target.value;
    setEmail(changedEmail);
    props.onChange(name, changedEmail);
  };

  return (
    <div className={classes.billingDetailBox}>
      <div className={classes.billingDetail}>
        <Typography className={classes.billingDetailTitle} variant="h6" noWrap>
          {t("payment_billing_details")}
        </Typography>
        <div className={classes.billingRequired}>
          <Typography className={classes.billingRequiredStar} noWrap>
            *
          </Typography>
          <Typography className={classes.billingRequiredText} noWrap>
            {t("payment_billing_details_required")}
          </Typography>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.line}>
        <div className={classes.inputTitlePart}>
          <Typography className={classes.inputTitle} variant="body1">
            {t("payment_billing_details_name")}
          </Typography>
          <Typography className={classes.inputRequired} variant="body1">
            *
          </Typography>
        </div>
        <StoreTextField
          className={classes.textField}
          variant="outlined"
          placeholder={t("payment_billing_details_name_placeholder")}
          onChange={onNameChange}
          inputProps={{
            className: classes.textInput,
          }}
        />
      </div>
      <div className={classes.lastLine}>
        <div className={classes.inputTitlePart}>
          <Typography className={classes.inputTitle} variant="body1">
            {t("payment_billing_details_email")}
          </Typography>
          <Typography className={classes.inputRequired} variant="body1">
            *
          </Typography>
        </div>
        <StoreTextField
          className={classes.textField}
          variant="outlined"
          placeholder={t("payment_billing_details_email_placeholder")}
          onChange={onEmailChange}
          inputProps={{
            className: classes.textInput,
          }}
        />
      </div>
    </div>
  );
}

export default BillingDetail;
