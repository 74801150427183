import React from "react";
import { makeStyles, Grid } from "@material-ui/core";

export interface PackViewStickersProps {
  stickers: Array<string>;
  isPC: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    left: 0,
    right: 0,
    backgroundColor: theme.greyscaleColorTheme.lightGrey1.main,
  },
  packViewStickers: {
    maxWidth: "960px",
    margin: "auto",
    padding: "12px 12px 4px 12px",
  },
  sticker: {
    width: "60px",
    height: "60px",
    marginBottom: "8px",
  },
}));

function PackViewStickers(props: PackViewStickersProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.packViewStickers}>
        <Grid
          container
          spacing={props.isPC ? 4 : 3}
          justify="center"
          alignItems="center"
        >
          {props.stickers.map((sticker, index) => (
            <Grid item xs key={index}>
              <img className={classes.sticker} src={sticker} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default PackViewStickers;
