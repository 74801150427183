import { AxiosResponse } from "axios";
import http from "../http-product";
import { StickerPack } from "../types/ResponseSchema";

export interface PackResBody {
  data: StickerPack;
}
export interface PacksByCreatorIdResBody {
  data: StickerPack[];
}

const getPack = async (packId: string) => {
  const token = sessionStorage.getItem("token");
  if (token === null) {
    throw new Error(
      `Failed to get token from device, ${JSON.stringify(sessionStorage)}`
    );
  }

  let result: AxiosResponse<PackResBody>;

  try {
    result = await http.get(`/packs/${packId}`, {
      headers: { "Mojitok-User-Access-Token": token },
    });
  } catch (err) {
    throw new Error(`Failed to request packs api, err: ${err})`);
  }

  if (result.status !== 200) {
    throw new Error(
      `Failed to request packs api, invalid status(${result.status})`
    );
  }

  return result.data;
};

const getPacksByCreatorId = async (creatorId: string) => {
  const token = sessionStorage.getItem("token");
  if (token === null) {
    throw new Error(
      `Failed to get token from device, ${JSON.stringify(sessionStorage)}`
    );
  }
  let lang = sessionStorage.getItem("language");
  if (lang === null) {
    lang = "en";
  }
  let result: AxiosResponse<PacksByCreatorIdResBody>;

  try {
    result = await http.get(`/packs`, {
      headers: { "Mojitok-User-Access-Token": token },
      params: {
        creator_id: creatorId,
        lang: lang,
        include: "CREATOR",
      },
    });
  } catch (err) {
    throw new Error(`Failed to request packs by creator id api, err: ${err})`);
  }

  if (result.status !== 200) {
    throw new Error(
      `Failed to request packs by creator id api, invalid status(${result.status})`
    );
  }

  return result.data;
};

export default {
  getPack,
  getPacksByCreatorId,
};
