import { AxiosResponse } from "axios";
import http from "../http-product";
import { TagKeyword, StickerPack } from "../types/ResponseSchema";

export interface SearchTagResBody {
  data: TagKeyword;
}
export interface SearchPackResBody {
  data: StickerPack[];
}

const getTrendingTag = async () => {
  let result: AxiosResponse<SearchTagResBody>;
  try {
    result = await http.get(`/tags/keyword`);
    //Tag 기능 API 미구현
  } catch (err) {
    throw new Error(`Failed to request keyword, err: ${err})`);
  }

  if (result.status !== 200) {
    throw new Error(
      `Failed to request keyword, invalid status(${result.status})`
    );
  }

  return result.data;
};
const getNewPacks = async () => {
  let result: AxiosResponse<SearchPackResBody>;
  try {
    result = await http.get(`/packs/new`);
    //New Pack 기능 API 미구현
  } catch (err) {
    throw new Error(`Failed to request new packs, err: ${err})`);
  }

  if (result.status !== 200) {
    throw new Error(
      `Failed to request new packs, invalid status(${result.status})`
    );
  }

  return result.data;
};

const searchPacks = async (query: string, language: string) => {
  const token = sessionStorage.getItem("token");
  if (token === null) {
    throw new Error(
      `Failed to get token from device, ${JSON.stringify(sessionStorage)}`
    );
  }

  let result: AxiosResponse<SearchPackResBody>;
  try {
    result = await http.get(`/packs/search`, {
      headers: { "Mojitok-User-Access-Token": token },
      params: {
        q: query,
        lang: language,
        include: "CREATOR",
      },
    });
  } catch (err) {
    throw new Error(`Failed to request new packs, err: ${err})`);
  }

  if (result.status !== 200) {
    throw new Error(
      `Failed to request new packs, invalid status(${result.status})`
    );
  }

  return result.data;
};

export default {
  getTrendingTag,
  getNewPacks,
  searchPacks,
};
