import { makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import { newLineToDiv, parseJwtPayload } from '../../utils/utils'
import { useTranslation } from 'react-i18next'

export interface PaymentFailInfoProps {
    productId: string,
    productType: string,
}

const useStyles = makeStyles((theme) => ({
    paymentFailBox: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        flexFlow: 'column',
        backgroundColor: 'rgba(255, 111, 80, 0.08)',
        padding: '24px 16px 24px 16px',
    },
    apologizeImage: {
        height: '100px',
    },
    copyrightText: {
        marginTop: '8px',
        color: theme.greyscaleColorTheme.grey2.main,
    },
    apologizeText: {
        marginTop: '20px',
        color: theme.greyscaleColorTheme.darkGrey.main,
    },
    errorDescriptionText: {
        marginTop: '16px',
        color: theme.greyscaleColorTheme.grey2.main,
        ...theme.customTypography.body3,
    },
    emailDescription: {
        marginTop: '24px',
        color: theme.greyscaleColorTheme.grey2.main,
        ...theme.customTypography.body3,
    },
    paymentInfoBox: {
        height: '140px',
        marginTop: '24px',
        padding: '16px',
        alignContent: 'center',
        border: `0.5px solid ${theme.greyscaleColorTheme.lightGrey3.main}`,
        backgroundColor: theme.greyscaleColorTheme.white.main,
    },
    paymentInfoText: {
        textAlign: 'left',
        color: theme.brandColorTheme.orange.main,
    },
}))

function PaymentFailInfo(props: PaymentFailInfoProps) {
    const classes = useStyles()

    const { t } = useTranslation();
    const apologizeText = newLineToDiv(t(`error_inconvenience`))
    const errorDescriptionText = newLineToDiv(t(`error_contact_us`))
    const emailDescriptionText = newLineToDiv(t(`error_capture_and_mail_us`))
    const accessToken = sessionStorage.getItem('token')
    const productID = props.productId
    const paymentPlatform = sessionStorage.getItem('paymentType')
    const productType = props.productType
    let customerID = ""

    if(accessToken !== null){
        try{
            customerID = parseJwtPayload(accessToken)["userId"]
        }
        catch(e){
            console.log(e);
        }
    }

    return (
        <div className={classes.paymentFailBox}>
            <img className={classes.apologizeImage} src={require('../../img/payment-fail.svg')} />
            <Typography className={classes.copyrightText} variant='caption'>© lamda</Typography>
            <Typography className={classes.apologizeText} variant='h4'>{apologizeText}</Typography>
            <Typography className={classes.errorDescriptionText}>{errorDescriptionText}</Typography>
            <div className={classes.paymentInfoBox}>
                <Typography className={classes.paymentInfoText} variant='subtitle2'>{t('payment_fail_customer_id')}</Typography>
                <Typography className={classes.paymentInfoText} style={{ marginTop: '4px' }} variant='subtitle2'>[{customerID}]</Typography>
                <Typography className={classes.paymentInfoText} style={{ marginTop: '8px' }} variant='subtitle2'>{t('payment_fail_product_id')}</Typography>
                <Typography className={classes.paymentInfoText} style={{ marginTop: '4px' }} variant='subtitle2'>[{productID}]</Typography>
                <Typography className={classes.paymentInfoText} style={{ marginTop: '8px' }} variant='subtitle2'>{t('payment_fail_payment_platform')} {paymentPlatform}</Typography>
                <Typography className={classes.paymentInfoText} style={{ marginTop: '4px' }} variant='subtitle2'>{t('payment_fail_product_type')} {productType}</Typography>
            </div>
            <Typography className={classes.emailDescription}>{emailDescriptionText}</Typography>
        </div>
    )
}

export default PaymentFailInfo;