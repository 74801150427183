export const purchaseFail = () => {
  const event = new CustomEvent("purchaseFail");
  window.dispatchEvent(event);
};

export const purchaseStart = () => {
  const event = new CustomEvent("purchaseStart");
  window.dispatchEvent(event);
};

export const purchaseFinish = () => {
  const event = new CustomEvent("purchaseFinish");
  window.dispatchEvent(event);
};

export const purchaseCancel = () => {
  const event = new CustomEvent("purchaseCancel");
  window.dispatchEvent(event);
};

export const settingDone = () => {
  const event = new CustomEvent("settingDone");
  window.dispatchEvent(event);
};
