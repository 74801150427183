import React from "react";
import { Link } from "react-router-dom";
import { Divider, Drawer, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface MainDrawerProps {
  open: boolean;
  onClose: (event: object) => void;
}

const useStyles = makeStyles((theme) => ({
  category: {
    marginTop: "29px",
    marginLeft: "24px",
  },
  categoryTitle: {
    color: theme.greyscaleColorTheme.grey2.main,
    display: "none",
  },
  categoryItemTitle: {
    marginTop: "18px",
    color: theme.greyscaleColorTheme.darkGrey.main,
    ...theme.typography.body1,
  },
  divider: {
    marginTop: "13px",
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: "0.5px",
  },
}));

function MainDrawer(props: MainDrawerProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <div className={classes.category}>
        <Typography
          className={classes.categoryTitle}
          variant="subtitle1"
          noWrap
        >
          {t("nav_drawer_menu_my_page")}
        </Typography>
        <Link
          to="/mypacks"
          style={{ textDecoration: "none" }}
          onClick={props.onClose}
        >
          <Typography className={classes.categoryItemTitle} variant="h6" noWrap>
            {t("nav_drawer_menu_my_stickers")}
          </Typography>
        </Link>
      </div>
      {/* <Divider className={classes.divider} />
        <div className={classes.category}>
          <Typography className={classes.categoryTitle} variant='subtitle1' noWrap>고객센터</Typography>
          <Typography className={classes.categoryItemTitle} variant='h6' noWrap>자주하는 질문</Typography>
          <Typography className={classes.categoryItemTitle} variant='h6' noWrap>문의하기</Typography>
        </div> */}
    </Drawer>
  );
}

export default MainDrawer;
