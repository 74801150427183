import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import PackService from "../../services/PackService";

export interface CreatorPackProps {
  packId: string;
  onClickPack: Function;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    border: "0.5px solid #CCCCCC",
    marginLeft: "2px",
    marginRight: "2px",
    padding: "10px 10px 0px 10px",
  },
  creatorPackList: {
    maxWidth: "960px",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
  },
  creatorThumbnail: {
    width: "50px",
    height: "50px",
    padding: "1.5px",
  },
  creatorPackName: {
    height: "36px",
    display: "flex",
    marginBottom: "7px",
  },
  creatorPackNameText: {
    maxWidth: "130px",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    webkitLineClamp: 2,
    webkitBoxOrient: "vertical",
    margin: "auto",
    ...theme.customTypography.body4,
  },
}));

function CreatorPack(props: CreatorPackProps) {
  const classes = useStyles();
  const [stickerList, setStickerList] = useState<Array<string>>([]);
  const [packName, setPackName] = useState("");

  useEffect(() => {
    async function getPackAsync(packId: string) {
      try {
        const stickerPack = (await PackService.getPack(packId)).data;

        const { name, stickers } = stickerPack;

        let foundName = "";
        let stickerThumbnails: string[] = [];

        try {
          foundName = name.find(
            (lang: { content: string; language: string }) =>
              lang.language === "en"
          )!!.content;
        } catch (err) {
          console.log(`Failed to get thumbnail or name from packs, ${err}`);
        }

        for (let i = 0; i < stickers.length; i++) {
          if (i >= 3) break;
          stickerThumbnails.push(
            stickers[i].images.find((image) => image.spec === "w300")!!.url
          );
        }
        setPackName(foundName);
        setStickerList(stickerThumbnails);
      } catch (e) {
        // pack id로 팩 정보를 가져오지 못한 경우
        console.log(e);
        alert(`Failed to get proper pack Id, ${packId}`);
      }
    }

    getPackAsync(props.packId);
  }, [props.packId]);

  return (
    <div
      key={props.packId}
      onClick={() => {
        props.onClickPack(props.packId);
      }}
      className={classes.root}
    >
      <div className={classes.creatorPackList}>
        {stickerList.map((url, index) => {
          return (
            <img
              key={props.packId + index}
              className={classes.creatorThumbnail}
              src={url}
              alt="w300_url"
            ></img>
          );
        })}
      </div>
      <div className={classes.creatorPackName}>
        <Typography className={classes.creatorPackNameText}>
          {packName}
        </Typography>
      </div>
    </div>
  );
}

export default CreatorPack;
