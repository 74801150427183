import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  CircularProgress,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PackViewInfo from "../components/product/PackViewInfo";
import PackViewStickers from "../components/product/PackViewStickers";
import packService from "../services/PackService";
import { getOwnership } from "../utils/ownershipInSessionStorage";
import ProductInformation from "../components/product/ProductInformation";
import { isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import CreatorPackList from "../components/product/CreatorPackList";

export interface PackViewProps {
  isPC: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  progressBox: {
    display: "flex",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255,0.5)",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 99998,
  },
  circularProgress: {
    color: theme.brandColorTheme.mojitokBlue.main,
    position: "fixed",
    zIndex: 99999,
  },
  moreStickerPacksText: {
    display: "flex",
    marginTop: "18px",
    marginBottom: "11px",
    marginLeft: "24px",
    marginRight: "24px",
  },
  moreStickerPackTextMore: {
    fontFamily: "Noto Sans KR Regular",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "-0.25px",
  },
  moreStickerPackTextCreatorName: {
    fontFamily: "Noto Sans KR BOLD",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.25px",
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,

    margin: "0px",
  },
}));

function PackView({ isPC }: PackViewProps) {
  let location = useLocation();
  let history = useHistory();
  const classes = useStyles();
  const [packId, setPackId] = useState("");
  const [packName, setPackName] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const [creatorId, setCreatorId] = useState("");
  const [price, setPrice] = useState(0);
  const [priceText, setPriceText] = useState("");
  const [priceType, setPriceType] = useState("");
  const [promotion, setPromotion] = useState("");
  const [stickers, setStickers] = useState<Array<string>>([]);
  const [isPurchased, setIsPurchased] = useState(false);
  const [productType, setProductType] = useState("");
  const [isWaitingPurchase, setIsWaitingPurchase] = useState(false);
  const [creatorPackList, setCreatorPackList] = useState<Array<string>>([]);
  const { t } = useTranslation();

  const checkPackIsPurchasedInOwnership = (packId: string) => {
    try {
      const ownership = getOwnership(packId);
      if (ownership !== undefined) {
        if (ownership.stickerPackId === packId) {
          setIsPurchased(true);
          setIsWaitingPurchase(false);
        } else {
          console.log(
            `Saved ownership in sessionStorage is corrupted, ${packId}`
          );
        }
      }
    } catch (err) {
      console.log(`Failed to get ownership from sessionStorage, err: ${err}`);
    }
  };

  async function getPacksByCreatorId(creatorId: string) {
    try {
      const packId =
        location.search && new URLSearchParams(location.search).get("packId");

      const stickerPacks = (await packService.getPacksByCreatorId(creatorId!))
        .data;

      const packIdList = stickerPacks.map((pack) => pack.id);
      setCreatorPackList(packIdList);
    } catch (e) {
      console.log(e);
      // alert(`Failed to get proper pack by creator Id, ${creatorId}`);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const packId =
      location.search && new URLSearchParams(location.search).get("packId");

    if (!packId || typeof packId !== "string") {
      //package id 없음 홈으로 리다이렉트
      history.push("/");
    }

    setPackId(packId!);

    async function getPackAsync() {
      try {
        const stickerPack = (await packService.getPack(packId!)).data;

        const {
          name,
          thumbnails,
          creator,
          product,
          promotions,
          stickers,
        } = stickerPack;

        let thumbnail: string;
        let foundName = "";
        let priceText: string | undefined = "";
        let price: number = 0;
        const osType = sessionStorage.getItem("osType");
        try {
          thumbnail = thumbnails.find((image) => image.spec === "w250")!!.url;
          foundName = name.find(
            (lang: { content: string; language: string }) =>
              lang.language === "en"
          )!!.content;
          if (osType !== "ANDROID" && osType !== "IOS") {
            //web sdk
            price = product.price?.find((price) => price.currency === "USD")
              ?.price!;
            priceText = "$" + price?.toString();
          } else {
            const skuDetail = sessionStorage.getItem("skuDetail");

            if (skuDetail) {
              const skuObj: {
                price: string;
                productType: string;
              }[] = JSON.parse(skuDetail);

              Object.entries(skuObj).forEach(([key, value]) => {
                /** TODO : Android SDK에서 stickerpack으로 넘어옴
                 *  후에 swagger로 맞추어 "STICER_PACK"으로 혼동없도록 같이 변경하기
                 *  https://gitlab.com/mojitok/mojitok-cloud/mjt-cloud-docs-swagger/-/blob/master/swagger.yaml
                 */

                if (value.productType === "STICKER_PACK") {
                  priceText = value.price;
                }
              });
            }
          }
        } catch (err) {
          console.log(`Failed to get thumbnail or name from packs, ${err}`);
          thumbnail = "";
        }

        setPackName(foundName);
        setThumbnail(thumbnail);
        setCreatorName(
          creator!!.nickname.find((name) => name.language === "en")!!.content
        );
        setCreatorId(creator!!.id);
        if (product.priceType === "FREE") {
          setPriceText(t("product_packview_info_free"));
        } else {
          if (!priceText) {
            priceText = "$ 1.99";
          }
          setPriceText(priceText);
          setPrice(price);
        }
        setPromotion(t("product_packview_info_no_expired"));
        setStickers(
          stickers.map((sticker) => {
            return sticker.images.find((image) => image.spec === "w300")!!.url;
          })
        );
        setProductType(product.productType);
        setPriceType(product.priceType);
        if (creatorId) {
          getPacksByCreatorId(creatorId);
        }
      } catch (e) {
        // pack id로 팩 정보를 가져오지 못한 경우
        console.log(e);
        alert(`Failed to get proper pack Id, ${packId}`);
        history.push("/");
      }
    }
    getPackAsync();
  }, [location]);

  useEffect(() => {
    if (creatorId) {
      getPacksByCreatorId(creatorId);
    }
  }, [creatorId]);

  useEffect(() => {
    checkPackIsPurchasedInOwnership(packId);
  }, [packId]);

  const handleUpdateOwnershipEvent = useCallback(() => {
    checkPackIsPurchasedInOwnership(packId);
  }, [packId]);

  const openPaymentFailPage = (packId: string, productType: string) => {
    history.push(`/payment-fail?packId=${packId}&productType=${productType}`);
  };

  const handlePaymentFailEvent = useCallback(() => {
    openPaymentFailPage(packId, productType);
  }, [packId, productType]);

  useEffect(() => {
    window.addEventListener(
      "updateOwnership",
      handleUpdateOwnershipEvent,
      false
    ); // sessionStorage에서 ownership 키 값이 갱신
    window.addEventListener("paymentFail", handlePaymentFailEvent, false);
    return () => {
      window.removeEventListener("updateOwnership", handleUpdateOwnershipEvent);
      window.removeEventListener("paymentFail", handlePaymentFailEvent);
    };
  }, [handlePaymentFailEvent, handleUpdateOwnershipEvent]);

  const onPurchaseStart = () => {
    document.body.style.overflow = "hidden";
    setIsWaitingPurchase(true);
  };
  const onPurchaseFinish = () => {
    document.body.style.overflow = "unset";
  };
  const onPurchaseFail = () => {
    setIsWaitingPurchase(false);
    document.body.style.overflow = "unset";
  };
  const onPurchaseCancel = () => {
    setIsWaitingPurchase(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    if (isAndroid || isIOS) {
      window.addEventListener("purchaseStart", onPurchaseStart, false);
      window.addEventListener("purchaseFinish", onPurchaseFinish, false);
      window.addEventListener("purchaseFail", onPurchaseFail, false);
      window.addEventListener("purchaseCancel", onPurchaseCancel, false);
      return () => {
        window.removeEventListener("purchaseStart", onPurchaseStart);
        window.removeEventListener("purchaseFinish", onPurchaseFinish);
        window.removeEventListener("purchaseFail", onPurchaseFail);
        window.removeEventListener("purchaseCancel", onPurchaseCancel);
        document.body.style.overflow = "unset";
      };
    }
  }, []);

  return (
    <>
      {isWaitingPurchase ? (
        <div className={classes.progressBox}>
          <CircularProgress className={classes.circularProgress} size={24} />
        </div>
      ) : null}

      <div className={classes.root}>
        <PackViewInfo
          packId={packId!}
          packName={packName}
          thumbnail={thumbnail}
          creatorName={creatorName}
          price={price}
          priceText={priceText}
          priceType={priceType}
          productType={productType}
          promotion={promotion}
          isPurchased={isPurchased}
          isWaitingPurchase={isWaitingPurchase}
        />
        <PackViewStickers stickers={stickers} isPC={isPC} />
        {creatorPackList.length <= 1 ? null : (
          <div>
            <div className={classes.moreStickerPacksText}>
              <Typography
                variant="subtitle1"
                className={classes.moreStickerPackTextMore}
              >
                More Stickers by&nbsp;
              </Typography>
              <Typography
                variant="body1"
                className={classes.moreStickerPackTextCreatorName}
              >
                {creatorName}
              </Typography>
            </div>
            <CreatorPackList
              selectedPackId={packId}
              packIdList={creatorPackList}
            ></CreatorPackList>
            <Divider className={classes.divider} />
          </div>
        )}

        <ProductInformation isPC={isPC} />
      </div>
    </>
  );
}

export default PackView;
